import React, { FC, useRef, useEffect, useState } from 'react'
import Navigation from './Navigation'
import MastImage from './MastImage'
import Carousel from './Carousel'
import MastContent from './MastContent'

type MastProps = {
  image: string
  quote?: string
  height: number
  article: string
  title?: string
  overlay: boolean
  homePage: boolean
}

const MastHead: FC<MastProps> = ({
  height,
  title,
  article,
  overlay,
  homePage,
}) => {
  const articleStyle = !homePage
    ? { height: '50vh', maxHeight: '480px' }
    : { maxHeight: '100vh' }

  //refs
  const navRef = useRef<HTMLDivElement>(null)
  const mastRef = useRef<HTMLDivElement>(null)

  // State
  const [prevScrollTop, setPrevScrollTop] = useState<number>(0)
  const [newScrollTop, setNewScrollTop] = useState<number>(0)
  const [isSticky, setSticky] = useState<boolean>(false)
  const [showNav, setShowNav] = useState<boolean>(true)
  const [navOffset, setNavOffset] = useState<number>(0)

  const handleScroll = () => {
    const currentOffset = window.pageYOffset
    if (mastRef.current) {
      setSticky(currentOffset > mastRef.current.offsetHeight + 64)
    }
    setNewScrollTop(window.pageYOffset)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [mastRef])

  useEffect(() => {
    if (navRef.current) {
      setNavOffset(navRef.current.getBoundingClientRect().top)
    }
  }, [mastRef])

  useEffect(() => {
    const showNav = window.pageYOffset < prevScrollTop
    setShowNav(showNav)

    return () => {
      setPrevScrollTop(newScrollTop)
    }
  }, [newScrollTop])
  return (
    <div className="masthead">
      <div
        ref={mastRef}
        className="fullscreen"
        style={{
          position: 'relative',
          alignItems: 'center',
          transition: 'all 0.3s ease 2s',
          height: '100vh',
          ...articleStyle,
          maxHeight: `${height ? `${height}px` : '100vh'}`,
        }}
      >
        {!homePage ? (
          <>
            <MastImage imageSlug={article} />
            <MastContent
              className={article ? 'header-content--article' : ''}
              title={title}
            />
          </>
        ) : (
          <div style={{ overflow: 'hidden' }}>
            <Carousel />
          </div>
        )}
        {overlay && <div className="overlay"></div>}

        {/* <div className="header-content header-content--article">
        </div> */}
      </div>
      <Navigation showNav={showNav} sticky={isSticky} ref={navRef} />
    </div>
  )
}

export default MastHead
