import React, { FC, useState, useEffect, useRef } from 'react'
import Slider from 'react-slick'
import MastImage from './MastImage'
import MastContent from './MastContent'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import headers from '@data/headers.json'

type T = {
  className?: string
}

const Carousel: FC<T> = ({ className, children }) => {
  const slickRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(true)
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    customPaging: (i: number) => {
      return (
        <button
          // ariaCurrent={i}
          tabIndex={0}
          aria-label={`Ga naar slide ${i}`}
          title={`Ga naar slide ${i}`}
          className={`pagination__item swiper-pagination white js-slide-${i}`}
        >
          <span className="inner "></span>
        </button>
      )
    },
    appendDots: dots => <SlickNavigation dots={dots} />,
    pauseOnHover: true,
    infinite: true,
    arrows: false,
    speed: 1000,
    easing: 'ease-in-out',
    slidesToShow: 1,
  }

  const toggle = () => {
    isPlaying ? slickRef.current.slickPause() : slickRef.current.slickPlay()
    console.log(slickRef.current)
    setIsPlaying(current => !current)
  }

  const SlickNavigation: FC = ({ dots }: { dots: any }) => (
    <div className="swiper-nav">
      <ul style={{ margin: '0px' }}> {dots} </ul>
      <button
        className="swiper-play swiper-toggle-js"
        role="button"
        onClick={toggle}
        title={
          isPlaying
            ? 'Pauzeer het automatisch doordraaien van de afbeeldingen'
            : 'Herstart het automatisch doordraaien van de afbeeldingen'
        }
      >
        <img
          className="swiper-play__icon"
          src={isPlaying ? '/assets/pause-solid.svg' : '/assets/play-solid.svg'}
        />
      </button>
    </div>
  )

  const Slide = ({
    title,
    mobile,
    image,
  }: {
    title: string
    mobile: string
    image: string
  }) => {
    return (
      <div className="slide">
        <MastImage homepage={true} className="slide" imageSlug={image} />
        <MastContent title={title} mobile={mobile} quotes={true} />
        <div className="overlay">
          {/* <img
            class="overlay__logo"
            alt="logo van het Ministerie van OCW"
            src="/assets/images/partners/ocw-logo-white.svg"
          ></img> */}
        </div>
      </div>
    )
  }
  return (
    <>
      <Slider {...settings} ref={slickRef} className={className}>
        {headers.map(({ title, mobile, image }, index) => (
          <Slide key={index} title={title} mobile={mobile} image={image} />
        ))}
      </Slider>
    </>
  )
}

//   return (
//     <Slider  {...settings} className="carousel">
//       {images.map((el, i) => (
//         <div key={i} className="">
//           {/* <div className="overlay"></div> */}
//           <div className="background-header background-header--story  background-header--xl"
//             style={{backgroundImage: `url('/assets/images/verhalen/16x9_07_Corona.jpg')`}}>
//           </div>
//             {/* <MastImage imageSlug="07_conrad" /> */}
//           <div className="header-content">
//             {/* {{#if mobile}}
//             <blockquote className="blockquote desktop blockquote--header">
//               {{breaklines title}}”
//             </blockquote>
//             <blockquote className="blockquote mobile blockquote--header">
//               {{title}}”
//             </blockquote>
//             {{else}}
//             <blockquote className="blockquote blockquote--header">
//               {{breaklines title}}”
//             </blockquote>
//             {{/if}} */}
//           </div>
//       </div>
//       )

//       )}
//     </Slider>
//   )

// }

export default Carousel
