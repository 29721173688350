import React, { FC, forwardRef, useState, useEffect } from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { Link } from 'gatsby'
const Navigation = forwardRef(
  ({ sticky, showNav }: { sticky: boolean; showNav: boolean }, ref) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
      <nav className={sticky ? 'stickyNav' : ''}>
        <div
          style={{ maxHeight: isOpen ? '380px' : '64px' }}
          ref={ref}
          className={`nav-container ${isOpen ? 'nav-open' : ''} ${
            sticky ? 'sticky' : ''
          } ${showNav ? 'show-nav' : ''}`}
          id="nav-container"
        >
          <div className="mobile-container">
            <div>
              <span className="title secondary">Werken in het onderwijs</span>
              <span className="title">
                <strong>De baan van het leven</strong>
              </span>
            </div>

            <button
              title="menu"
              aria-expanded={isOpen}
              aria-label="Menu navigatie schakelaar"
              aria-controls="navigation-menu"
              onClick={() => setIsOpen(current => !current)}
              id="nav-toggler"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          <div className="nav-menu">
            <ul id="navigation-menu">
              <li onClick={() => setIsOpen(false)}>
                <Link to="/#intro">De baan van het leven</Link>
              </li>
              <li onClick={() => setIsOpen(false)}>
                <Link to="/#stories">Verhalen uit het onderwijs</Link>
              </li>
              <li onClick={() => setIsOpen(false)}>
                <Link to="/#techniek">Aan de slag in het onderwijs</Link>
              </li>
              <li onClick={() => setIsOpen(false)}>
                <Link to="/#partnerships">Partners</Link>
              </li>
              <li onClick={() => setIsOpen(false)}>
                <Link to="/#campagne">Deel verhalen</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
)

export default Navigation
