import React, { FC, useState, useEffect } from 'react'

type T = {
  title: string
  mobile?: string
  className?: string
  quotes?: boolean
}

const MastImage: FC<T> = ({ title, mobile, className, quotes }) => {
  const [hasQuotes, setHasQuotes] = useState<boolean>(false)
  const [formattedTitle, setFormattedTitle] = useState(title)
  useEffect(() => {
    if (!title) return
    setHasQuotes(title.startsWith('“') || quotes === true)
    setFormattedTitle(title.replace(/“|”/g, ''))
  }, [title])

  return (
    <div className={`header-content ${className}`}>
      {mobile && title && (
        <>
          <blockquote
            className={`blockquote desktop ${
              hasQuotes ? 'blockquote-quotes' : ''
            }`}
          >
            {formattedTitle}
          </blockquote>
          <blockquote
            className={`blockquote mobile ${
              hasQuotes ? 'blockquote-quotes' : ''
            }`}
          >
            {formattedTitle}
          </blockquote>
        </>
      )}
      {!mobile && title && (
        <blockquote
          className={`blockquote ${hasQuotes ? 'blockquote-quotes' : ''}`}
        >
          {formattedTitle}
        </blockquote>
      )}
    </div>
  )
}

export default MastImage
