import React, { FC } from 'react'

type T = {
  imageSlug: string
  className?: string
  homepage?: boolean
}

const MastImage: FC<T> = ({ imageSlug, className, homepage }) => {
  return homepage ? (
    <>
      <div
        className={`background-header background-header--story  background-header--mobile ${className}`}
        style={{
          backgroundImage: `url('/assets/images/9x16_${imageSlug}.jpg')`,
        }}
      ></div>

      {/* Normal */}
      <div
        className="background-header background-header--story  background-header--desktop"
        style={{
          backgroundImage: `url('/assets/images/1366x768_${imageSlug}.jpg')`,
        }}
      ></div>

      {/* XL */}
      <div
        className="background-header background-header--story  background-header--xl"
        style={{
          backgroundImage: `url('/assets/images/16x9_${imageSlug}.jpg')`,
        }}
      ></div>
    </>
  ) : (
    <>
      <div
        className={`background-header background-header--story  background-header--mobile ${className}`}
        style={{
          backgroundImage: `url('${
            imageSlug
              ? `../../${imageSlug}`
              : `../../assets/images/9x16_Pythagoras.jpg`
          }')`,
        }}
      ></div>

      <div
        className="background-header background-header--story  background-header--desktop"
        style={{
          backgroundImage: `url('${
            imageSlug
              ? `../../${imageSlug}`
              : `../../assets/images/1366x768_Pythagoras.jpg`
          }')`,
        }}
      ></div>

      <div
        className="background-header background-header--story  background-header--xl"
        style={{
          backgroundImage: `url('${
            imageSlug
              ? `../../${imageSlug}`
              : `../../assets/images/16x9_Pythagoras.jpg`
          }')`,
        }}
      ></div>

      <div className="overlay">
        {/* <img
          class="overlay__logo"
          alt="logo van het Ministerie van OCW"
          src="/assets/images/partners/ocw-logo-white.svg"
        ></img> */}
      </div>
    </>
  )
}

export default MastImage
